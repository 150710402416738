<template>
  <div ref="ndaWrapper" style="text-align: left">
    <div
      class="spandiv"
      style="margin-top: 20px; display: flex; margin-bottom: 20px"
    >
      <b
        >THIS CONFIDENTIALITY AND NON - DISCLOSURE AGREEMENT ('this
        Agreement')</b
      >
      is made between Platohub Inc, a corporation registered under the laws of
      the State of Delaware and with registered office address at 1013 Centre
      Road, Suite 403-A, Wilmington, New Castle County, Delaware, 19805 (trading
      under the brand name 'Grupa') and you (on behalf of yourself or the
      business you represent).
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 20px">
      By registering and subscribing to the Grupa platform, you agree to be
      bound by the terms of this Agreement that apply for each country for which
      you register or elect to use a service ('Elected Country').
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 20px">
      In this Agreement, 'we', 'us', and 'Grupa' means the applicable Platohub
      Contracting Party and any of its applicable Affiliates,and 'you' means the
      applicant (if registering for or using a Service as an individual), or the
      business employing or representing the applicant (if registering for or
      using a service as a business) and any of its affiliates. You and Grupa
      means the 'Parties'. Capitalized terms have the meanings given to them in
      this Agreement. If there is a conflict among terms in this Agreement, the
      Service Level Agreement to be signed will prevail over this Agreement.
    </div>

    <div class="spandiv" style="display: flex; margin-bottom: 20px">
      WHEREAS:
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 20px">
      A. You are engaging Grupa in relation to possible engagement to build
      innovative solutions (Services)and in the course of this engagement, both
      Parties will come into contact with, obtain or receive information that is
      considered Confidential Information (as hereinafter defined).
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 20px">
      B. The Parties acknowledge that any confidential information exchanged
      between them should be kept confidential and have therefore agreed to
      enter into this Agreement.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 20px">
      C. To begin the enrollment process, you must complete the registration
      process for one or more of the Services. Use of the Services is limited to
      parties that can lawfully enter into and form contracts under applicable
      Law (for example, the Elected Country may not allow minors to use the
      Services). As part of the application, you must provide us with your (or
      your business) legal name, address, phone number and e-mail address.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      NOW IT IS HEREBY AGREED AS FOLLOWS:
    </div>
    <div class="spandiv" style="display: flex">
      <div class="divText" style="text-align: left; color: blue">
        1. DEFINITIONS
      </div>
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 20px">
      1.1 "Agreement" means this Confidentiality and Non-Disclosure Agreement
      between the Parties;
    </div>
    <div class="spandiv" style="display: flex">
      <div class="divText">
        1.2 "Confidential Information" means all information or data disclosed
        (whether in writing, orally or by any other means) to one party by the
        other party or by a third party on behalf of the other party and shall
        include but not be limited to;
      </div>
    </div>
    <div class="spandiv" style="display: flex">
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (a) Information relating to the organizational structure, marketing
        strategy and valuation of the Parties and any other information relating
        to the business operations, processes, plans, intentions, product
        information, know-how, design rights, trade secrets, software, market
        opportunities, customers and affairs of the Parties;
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (b) technical information to any products or services supplied by the
        Parties;
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (c) information relating to the officers, directors or employees of the
        Parties including their terms and conditions of employment or
        engagement;
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (d) the information which is disclosed orally and identified as
        confidential at the time of disclosure;
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (e) The existence and content of this Agreement and matters incidental
        thereto;
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (f) any information ascertainable by the inspection of accounts, and
        other records;
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (g) financial, legal and any other information provided in due diligence
        checklists to be provided after execution hereof;
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (h) any information relating to that Party business, operations, plans,
        intentions, market opportunities, customers and business affairs; and
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (i) any Intellectual Property.
      </div>
    </div>
    <div class="spandiv" style="display: flex">
      <div class="divText" style="text-align: left">
        But shall exclude any part of such disclosed information or data and
        information supplied by Grupa Product Owners, Partners, and Stakeholders
        of the project which:
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (a) is or becomes common or public knowledge in any way without breach
        of this Agreement by the receiving party; or
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (b) the consultant can show; (i) was in its possession or known to it by
        being in its use or being recorded in its files or computers or other
        recording media prior to receipt from the disclosing party and was not
        previously acquired by the receiving party from the disclosing party
        under an obligation of confidence; or (ii) to have been developed by or
        for the receiving party at any time independently of the information
        disclosed to it by the disclosing party; or
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (c) which is hereafter disclosed or made available to the receiving
        party from a source other than the disclosing party without breach by
        the receiving party or such source of any obligations of confidentiality
        or non-use towards the disclosing party; or
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (d) which is hereafter disclosed by the disclosing party to a third
        party without restriction on disclosure or use, including, without
        limitation, by way of the publication of a patent specification; or
      </div>
      <div
        class="divText"
        style="text-align: left; margin-left: 24px; margin-bottom: 24px"
      >
        (e) which is disclosed by the receiving Party with the prior written
        approval of the disclosing Party.
      </div>
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      1.3 Effective Date means the date upon which this agreement shall be
      deemed to have come into effect which shall be the date appearing
      hereinabove;
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      1.4 "Intellectual Property" shall mean any company name, registered name,
      trademark, logo, copyright, trade name, or other intellectual property
      right whatsoever of the disclosing Party, whether registered or not;
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      <span style="text-align: left; color: blue"
        >2.HANDLING OF CONFIDENTIAL INFORMATION</span
      >
      <div>
        In consideration of the disclosure of Confidential Information, the
        consultant undertakes in relation to Grupa and its Product Partners
        ("the Disclosing Party") Confidential Information:
      </div>
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      2.1 to maintain the same in confidence and to use it only for purposes of
      the negotiations contemplated herein
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      2.2 not to disclose the same except to its directors, officers, employees,
      agents, consultants, legal, tax and investment advisors (collectively, the
      "Representatives") and except in confidence to such of its Representatives
      who need to know the same for purposes of the information disclosures
      contemplated herein and that such Representatives are obliged not to
      disclose the same;
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      2.3 to be responsible for the performance of sub-clauses 2.1, and 2.2
      above on the part of its Representatives to whom the same is disclosed
      and;
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      2.4 to apply thereto no lesser security measures and degree of care than
      those which the consultant applies to its own Confidential Information and
      which the consultant warrants as providing adequate protection of such
      information from unauthorized disclosure, copying or use.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      <span style="text-align: left; color: blue"
        >3.LENGTH OF THIS AGREEMENT</span
      >
      <div style="margin-bottom: 24px">
        This Agreement shall be in force from the date herein stated and shall
        expire within 1 year from execution of this Agreement.
      </div>
    </div>
    <div class="spandiv" style="display: flex">
      <span style="text-align: left; color: blue"
        >4.OWNERSHIP AND NO WARRANTY</span
      >
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      4.1 Each Party reserves all rights in its Confidential Information and no
      rights or obligations other than those expressly cited herein are granted
      or to be implied from this Agreement.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      4.2 The Receiving Party shall not acquire hereunder any right whatsoever
      to any Confidential Information, including without limitation, any right
      or license of any patent, trademark, copyright, trade secret, moral right
      or any other right now or later recognized by any law or regulation of any
      jurisdiction as a result of or in connection with any disclosure
      hereunder. Accordingly, nothing in this Agreement is intended or shall be
      construed as a transfer, grant, license, release or waiver of any
      Intellectual Property Rights in any Confidential Information.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      4.3 No disclosure of any Confidential Information by the Parties shall
      constitute any representation or warranty by that Party regarding the
      accuracy of the same or the non-infringement of any patent, trademark,
      copyright or any other intellectual property or proprietary right.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      4.4 Nothing in this Agreement shall be deemed to obligate either Party to
      disclose any Confidential Information to the other, or to accept any
      Confidential Information of the other.
    </div>
    <div class="spandiv" style="display: flex">
      <span style="text-align: left; color: blue"
        >5.RETURN/DESTRUCTION OF CONFIDENTIAL INFORMATION</span
      >
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      5.1 Each Party shall immediately after the termination of this Agreement
      or at the request of the other Party either destroy or return all
      Confidential Information and any copies thereof in its possession and
      within thirty (30) days of receiving such a request certify in writing its
      compliance.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      5.2 This clause shall not apply to the extent that the Receiving Party is
      obliged to retain any Confidential Information in terms of any applicable
      law, rule or regulation or by any competent judicial, governmental,
      supervisory or regulatory body or in accordance with reasonable internal
      policy.
    </div>
    <div class="spandiv" style="display: flex">
      <span style="text-align: left; color: blue">6.FURTHER PROTECTION</span>
      <div style="margin-bottom: 24px">
        In the event that either Party becomes compelled by law or by order of
        any court or tribunal of competent jurisdiction to disclose any
        Confidential Information, the said Party shall give the other Party
        prompt notice but in any event not later than five (5) days' notice
        prior to such disclosure (where possible) so that the other Party may
        seek a protective order or other appropriate remedy and /or waive
        compliance with the provisions of this Agreement.
      </div>
    </div>
    <div class="spandiv" style="display: flex">
      <span style="text-align: left; color: blue">7.APPLICABLE LAW</span>
      <div style="margin-bottom: 24px">
        This Agreement shall be exclusively governed and construed in accordance
        with the laws of California and the same law shall exclusively govern
        the merits of any dispute arising out of or in connection with this
        Agreement.
      </div>
    </div>
    <div class="spandiv" style="display: flex">
      <span style="text-align: left; color: blue">8.DISPUTE RESOLUTION</span>
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      8.1 The Parties shall use their best efforts to settle amicably any
      dispute arising from or in connection with this Agreement or the
      interpretation thereof.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      8.2 If the dispute has not been settled pursuant to clause 8.1 above
      within 1 (one) month from when the mediation was instituted, any party may
      elect to seek redress at the relevant Chancery courts of law in Delaware.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      8.3 Pending final settlement or determination of a dispute, the Parties
      shall continue to perform their subsisting obligations hereunder.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      8.4 Nothing in this Agreement shall prevent or delay a Party seeking
      urgent injunctive or interlocutory relief in a court having jurisdiction.
    </div>
    <div class="spandiv" style="display: flex">
      <span style="text-align: left; color: blue">9.NOTICE</span>
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      9.1 The Parties choose their respective addresses as provided for the
      purposes of giving of any notice, the service of any process and for any
      purpose arising from this Agreement.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      9.2 Each of the Parties shall be entitled, by written notice to the other,
      to vary their address for notice from time to time to any other address
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      9.3 Any notice is given by one Party to the other ("the Addressee") which:
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      (a) is delivered by hand during the normal business hours of the Addressee
      at the Addressee's address for notice, for the time being, shall be
      deemed, (unless the contrary is proved by the Addressee), to have been
      received by the Addressee at the time of delivery;
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      (b) if transmitted by facsimile to the facsimile number forming a part of
      the Addressee's address for notice, or sent by e-mail in Microsoft
      Word/PDF format to the e-mail address of such person previously provided
      to the other Party, be deemed to have been received by the Addressee
      (unless the contrary is proved by the Addressee) one Business Day after
      the date of dispatch.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      9.4 Notwithstanding anything to the contrary contained in this Agreement,
      a written notice or communication actually received by one of the Parties
      from another will be adequate written notice or communication to such
      Party.
    </div>
    <div class="spandiv" style="display: flex">
      <span style="text-align: left; color: blue">10. NO PUBLICATION </span>
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      Neither Party shall disclose, publicize or advertise in any manner the
      discussions or negotiations contemplated by the Agreement without the
      prior written consent of the other Party, except as may be required by
      law.
    </div>
    <div class="spandiv" style="display: flex">
      <span style="text-align: left; color: blue">11. GENERAL </span>
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      1.1 This Agreement and any amendment hereto may be executed in
      counterparts, each of which when executed and delivered shall be deemed an
      original and all of which taken together shall constitute one and the same
      instrument. This Agreement may be delivered by hand or e-mail (as a signed
      PDF).
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      1.2 This Agreement shall not be deemed to create any partnership, agency
      or further relationship between the Parties, the rights, and obligations
      arising from this Agreement being restricted to this Agreement.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      1.3 This Agreement constitutes the entire Agreement and understanding
      between the parties in respect of Confidential Information and supersedes
      all previous agreements, understandings, and undertakings in such respect.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      1.4 This Agreement is personal to the parties and shall not be assigned or
      otherwise transferred in whole or in part by either party.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      1.5 The invalidity or unenforceability of any provision of this Agreement
      shall not affect the validity or enforceability of any other provision of
      this Agreement.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 24px">
      1.6 We may amend any of the terms and conditions contained in this
      Agreement at any time and at our sole discretion. Any changes will be
      effective upon the posting or updating such changes on the Grupa platform
      and you are responsible for reviewing and informing yourself of all
      applicable changes or notices.
    </div>
    <div class="spandiv" style="display: flex; margin-bottom: 50px">
      1.7 The signatories on behalf of the Parties warrant that they are duly
      authorized hereto.
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
</style>
