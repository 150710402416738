var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "ndaWrapper", staticStyle: { "text-align": "left" } },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "20px" },
        },
        [
          _vm._v(
            "\n    By registering and subscribing to the Grupa platform, you agree to be\n    bound by the terms of this Agreement that apply for each country for which\n    you register or elect to use a service ('Elected Country').\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "20px" },
        },
        [
          _vm._v(
            "\n    In this Agreement, 'we', 'us', and 'Grupa' means the applicable Platohub\n    Contracting Party and any of its applicable Affiliates,and 'you' means the\n    applicant (if registering for or using a Service as an individual), or the\n    business employing or representing the applicant (if registering for or\n    using a service as a business) and any of its affiliates. You and Grupa\n    means the 'Parties'. Capitalized terms have the meanings given to them in\n    this Agreement. If there is a conflict among terms in this Agreement, the\n    Service Level Agreement to be signed will prevail over this Agreement.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "20px" },
        },
        [_vm._v("\n    WHEREAS:\n  ")]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "20px" },
        },
        [
          _vm._v(
            "\n    A. You are engaging Grupa in relation to possible engagement to build\n    innovative solutions (Services)and in the course of this engagement, both\n    Parties will come into contact with, obtain or receive information that is\n    considered Confidential Information (as hereinafter defined).\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "20px" },
        },
        [
          _vm._v(
            "\n    B. The Parties acknowledge that any confidential information exchanged\n    between them should be kept confidential and have therefore agreed to\n    enter into this Agreement.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "20px" },
        },
        [
          _vm._v(
            "\n    C. To begin the enrollment process, you must complete the registration\n    process for one or more of the Services. Use of the Services is limited to\n    parties that can lawfully enter into and form contracts under applicable\n    Law (for example, the Elected Country may not allow minors to use the\n    Services). As part of the application, you must provide us with your (or\n    your business) legal name, address, phone number and e-mail address.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [_vm._v("\n    NOW IT IS HEREBY AGREED AS FOLLOWS:\n  ")]
      ),
      _vm._m(1),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "20px" },
        },
        [
          _vm._v(
            '\n    1.1 "Agreement" means this Confidentiality and Non-Disclosure Agreement\n    between the Parties;\n  '
          ),
        ]
      ),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    1.3 Effective Date means the date upon which this agreement shall be\n    deemed to have come into effect which shall be the date appearing\n    hereinabove;\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            '\n    1.4 "Intellectual Property" shall mean any company name, registered name,\n    trademark, logo, copyright, trade name, or other intellectual property\n    right whatsoever of the disclosing Party, whether registered or not;\n  '
          ),
        ]
      ),
      _vm._m(5),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    2.1 to maintain the same in confidence and to use it only for purposes of\n    the negotiations contemplated herein\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            '\n    2.2 not to disclose the same except to its directors, officers, employees,\n    agents, consultants, legal, tax and investment advisors (collectively, the\n    "Representatives") and except in confidence to such of its Representatives\n    who need to know the same for purposes of the information disclosures\n    contemplated herein and that such Representatives are obliged not to\n    disclose the same;\n  '
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    2.3 to be responsible for the performance of sub-clauses 2.1, and 2.2\n    above on the part of its Representatives to whom the same is disclosed\n    and;\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    2.4 to apply thereto no lesser security measures and degree of care than\n    those which the consultant applies to its own Confidential Information and\n    which the consultant warrants as providing adequate protection of such\n    information from unauthorized disclosure, copying or use.\n  "
          ),
        ]
      ),
      _vm._m(6),
      _vm._m(7),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    4.1 Each Party reserves all rights in its Confidential Information and no\n    rights or obligations other than those expressly cited herein are granted\n    or to be implied from this Agreement.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    4.2 The Receiving Party shall not acquire hereunder any right whatsoever\n    to any Confidential Information, including without limitation, any right\n    or license of any patent, trademark, copyright, trade secret, moral right\n    or any other right now or later recognized by any law or regulation of any\n    jurisdiction as a result of or in connection with any disclosure\n    hereunder. Accordingly, nothing in this Agreement is intended or shall be\n    construed as a transfer, grant, license, release or waiver of any\n    Intellectual Property Rights in any Confidential Information.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    4.3 No disclosure of any Confidential Information by the Parties shall\n    constitute any representation or warranty by that Party regarding the\n    accuracy of the same or the non-infringement of any patent, trademark,\n    copyright or any other intellectual property or proprietary right.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    4.4 Nothing in this Agreement shall be deemed to obligate either Party to\n    disclose any Confidential Information to the other, or to accept any\n    Confidential Information of the other.\n  "
          ),
        ]
      ),
      _vm._m(8),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    5.1 Each Party shall immediately after the termination of this Agreement\n    or at the request of the other Party either destroy or return all\n    Confidential Information and any copies thereof in its possession and\n    within thirty (30) days of receiving such a request certify in writing its\n    compliance.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    5.2 This clause shall not apply to the extent that the Receiving Party is\n    obliged to retain any Confidential Information in terms of any applicable\n    law, rule or regulation or by any competent judicial, governmental,\n    supervisory or regulatory body or in accordance with reasonable internal\n    policy.\n  "
          ),
        ]
      ),
      _vm._m(9),
      _vm._m(10),
      _vm._m(11),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    8.1 The Parties shall use their best efforts to settle amicably any\n    dispute arising from or in connection with this Agreement or the\n    interpretation thereof.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    8.2 If the dispute has not been settled pursuant to clause 8.1 above\n    within 1 (one) month from when the mediation was instituted, any party may\n    elect to seek redress at the relevant Chancery courts of law in Delaware.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    8.3 Pending final settlement or determination of a dispute, the Parties\n    shall continue to perform their subsisting obligations hereunder.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    8.4 Nothing in this Agreement shall prevent or delay a Party seeking\n    urgent injunctive or interlocutory relief in a court having jurisdiction.\n  "
          ),
        ]
      ),
      _vm._m(12),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    9.1 The Parties choose their respective addresses as provided for the\n    purposes of giving of any notice, the service of any process and for any\n    purpose arising from this Agreement.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    9.2 Each of the Parties shall be entitled, by written notice to the other,\n    to vary their address for notice from time to time to any other address\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            '\n    9.3 Any notice is given by one Party to the other ("the Addressee") which:\n  '
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    (a) is delivered by hand during the normal business hours of the Addressee\n    at the Addressee's address for notice, for the time being, shall be\n    deemed, (unless the contrary is proved by the Addressee), to have been\n    received by the Addressee at the time of delivery;\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    (b) if transmitted by facsimile to the facsimile number forming a part of\n    the Addressee's address for notice, or sent by e-mail in Microsoft\n    Word/PDF format to the e-mail address of such person previously provided\n    to the other Party, be deemed to have been received by the Addressee\n    (unless the contrary is proved by the Addressee) one Business Day after\n    the date of dispatch.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    9.4 Notwithstanding anything to the contrary contained in this Agreement,\n    a written notice or communication actually received by one of the Parties\n    from another will be adequate written notice or communication to such\n    Party.\n  "
          ),
        ]
      ),
      _vm._m(13),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    Neither Party shall disclose, publicize or advertise in any manner the\n    discussions or negotiations contemplated by the Agreement without the\n    prior written consent of the other Party, except as may be required by\n    law.\n  "
          ),
        ]
      ),
      _vm._m(14),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    1.1 This Agreement and any amendment hereto may be executed in\n    counterparts, each of which when executed and delivered shall be deemed an\n    original and all of which taken together shall constitute one and the same\n    instrument. This Agreement may be delivered by hand or e-mail (as a signed\n    PDF).\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    1.2 This Agreement shall not be deemed to create any partnership, agency\n    or further relationship between the Parties, the rights, and obligations\n    arising from this Agreement being restricted to this Agreement.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    1.3 This Agreement constitutes the entire Agreement and understanding\n    between the parties in respect of Confidential Information and supersedes\n    all previous agreements, understandings, and undertakings in such respect.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    1.4 This Agreement is personal to the parties and shall not be assigned or\n    otherwise transferred in whole or in part by either party.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    1.5 The invalidity or unenforceability of any provision of this Agreement\n    shall not affect the validity or enforceability of any other provision of\n    this Agreement.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "24px" },
        },
        [
          _vm._v(
            "\n    1.6 We may amend any of the terms and conditions contained in this\n    Agreement at any time and at our sole discretion. Any changes will be\n    effective upon the posting or updating such changes on the Grupa platform\n    and you are responsible for reviewing and informing yourself of all\n    applicable changes or notices.\n  "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "spandiv",
          staticStyle: { display: "flex", "margin-bottom": "50px" },
        },
        [
          _vm._v(
            "\n    1.7 The signatories on behalf of the Parties warrant that they are duly\n    authorized hereto.\n  "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "spandiv",
        staticStyle: {
          "margin-top": "20px",
          display: "flex",
          "margin-bottom": "20px",
        },
      },
      [
        _c("b", [
          _vm._v(
            "THIS CONFIDENTIALITY AND NON - DISCLOSURE AGREEMENT ('this\n      Agreement')"
          ),
        ]),
        _vm._v(
          "\n    is made between Platohub Inc, a corporation registered under the laws of\n    the State of Delaware and with registered office address at 1013 Centre\n    Road, Suite 403-A, Wilmington, New Castle County, Delaware, 19805 (trading\n    under the brand name 'Grupa') and you (on behalf of yourself or the\n    business you represent).\n  "
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: { "text-align": "left", color: "blue" },
          },
          [_vm._v("\n      1. DEFINITIONS\n    ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c("div", { staticClass: "divText" }, [
          _vm._v(
            '\n      1.2 "Confidential Information" means all information or data disclosed\n      (whether in writing, orally or by any other means) to one party by the\n      other party or by a third party on behalf of the other party and shall\n      include but not be limited to;\n    '
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (a) Information relating to the organizational structure, marketing\n      strategy and valuation of the Parties and any other information relating\n      to the business operations, processes, plans, intentions, product\n      information, know-how, design rights, trade secrets, software, market\n      opportunities, customers and affairs of the Parties;\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (b) technical information to any products or services supplied by the\n      Parties;\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (c) information relating to the officers, directors or employees of the\n      Parties including their terms and conditions of employment or\n      engagement;\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (d) the information which is disclosed orally and identified as\n      confidential at the time of disclosure;\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (e) The existence and content of this Agreement and matters incidental\n      thereto;\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (f) any information ascertainable by the inspection of accounts, and\n      other records;\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (g) financial, legal and any other information provided in due diligence\n      checklists to be provided after execution hereof;\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (h) any information relating to that Party business, operations, plans,\n      intentions, market opportunities, customers and business affairs; and\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [_vm._v("\n      (i) any Intellectual Property.\n    ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c(
          "div",
          { staticClass: "divText", staticStyle: { "text-align": "left" } },
          [
            _vm._v(
              "\n      But shall exclude any part of such disclosed information or data and\n      information supplied by Grupa Product Owners, Partners, and Stakeholders\n      of the project which:\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (a) is or becomes common or public knowledge in any way without breach\n      of this Agreement by the receiving party; or\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (b) the consultant can show; (i) was in its possession or known to it by\n      being in its use or being recorded in its files or computers or other\n      recording media prior to receipt from the disclosing party and was not\n      previously acquired by the receiving party from the disclosing party\n      under an obligation of confidence; or (ii) to have been developed by or\n      for the receiving party at any time independently of the information\n      disclosed to it by the disclosing party; or\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (c) which is hereafter disclosed or made available to the receiving\n      party from a source other than the disclosing party without breach by\n      the receiving party or such source of any obligations of confidentiality\n      or non-use towards the disclosing party; or\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (d) which is hereafter disclosed by the disclosing party to a third\n      party without restriction on disclosure or use, including, without\n      limitation, by way of the publication of a patent specification; or\n    "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "divText",
            staticStyle: {
              "text-align": "left",
              "margin-left": "24px",
              "margin-bottom": "24px",
            },
          },
          [
            _vm._v(
              "\n      (e) which is disclosed by the receiving Party with the prior written\n      approval of the disclosing Party.\n    "
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "spandiv",
        staticStyle: { display: "flex", "margin-bottom": "24px" },
      },
      [
        _c("span", { staticStyle: { "text-align": "left", color: "blue" } }, [
          _vm._v("2.HANDLING OF CONFIDENTIAL INFORMATION"),
        ]),
        _c("div", [
          _vm._v(
            '\n      In consideration of the disclosure of Confidential Information, the\n      consultant undertakes in relation to Grupa and its Product Partners\n      ("the Disclosing Party") Confidential Information:\n    '
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "spandiv",
        staticStyle: { display: "flex", "margin-bottom": "24px" },
      },
      [
        _c("span", { staticStyle: { "text-align": "left", color: "blue" } }, [
          _vm._v("3.LENGTH OF THIS AGREEMENT"),
        ]),
        _c("div", { staticStyle: { "margin-bottom": "24px" } }, [
          _vm._v(
            "\n      This Agreement shall be in force from the date herein stated and shall\n      expire within 1 year from execution of this Agreement.\n    "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c("span", { staticStyle: { "text-align": "left", color: "blue" } }, [
          _vm._v("4.OWNERSHIP AND NO WARRANTY"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c("span", { staticStyle: { "text-align": "left", color: "blue" } }, [
          _vm._v("5.RETURN/DESTRUCTION OF CONFIDENTIAL INFORMATION"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c("span", { staticStyle: { "text-align": "left", color: "blue" } }, [
          _vm._v("6.FURTHER PROTECTION"),
        ]),
        _c("div", { staticStyle: { "margin-bottom": "24px" } }, [
          _vm._v(
            "\n      In the event that either Party becomes compelled by law or by order of\n      any court or tribunal of competent jurisdiction to disclose any\n      Confidential Information, the said Party shall give the other Party\n      prompt notice but in any event not later than five (5) days' notice\n      prior to such disclosure (where possible) so that the other Party may\n      seek a protective order or other appropriate remedy and /or waive\n      compliance with the provisions of this Agreement.\n    "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c("span", { staticStyle: { "text-align": "left", color: "blue" } }, [
          _vm._v("7.APPLICABLE LAW"),
        ]),
        _c("div", { staticStyle: { "margin-bottom": "24px" } }, [
          _vm._v(
            "\n      This Agreement shall be exclusively governed and construed in accordance\n      with the laws of California and the same law shall exclusively govern\n      the merits of any dispute arising out of or in connection with this\n      Agreement.\n    "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c("span", { staticStyle: { "text-align": "left", color: "blue" } }, [
          _vm._v("8.DISPUTE RESOLUTION"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c("span", { staticStyle: { "text-align": "left", color: "blue" } }, [
          _vm._v("9.NOTICE"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c("span", { staticStyle: { "text-align": "left", color: "blue" } }, [
          _vm._v("10. NO PUBLICATION "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spandiv", staticStyle: { display: "flex" } },
      [
        _c("span", { staticStyle: { "text-align": "left", color: "blue" } }, [
          _vm._v("11. GENERAL "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }